import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import useNodePolicies from '@aurora/shared-client/components/nodes/useNodePolicies';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import type {
  Article,
  Board,
  MessageDraftHistoryEdge
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  ConversationStyle,
  WorkflowAction
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React, { useId, useState } from 'react';
import { Dropdown, useClassNameMapper, Modal } from 'react-bootstrap';
import isEqual from 'react-fast-compare';
import { CoAuthorsAndContributorsWorkflow, PublishedState } from '../../../types/enums';
import type {
  MessageActionMenuFragment,
  MessageDraftHistoryCountQuery,
  MessageDraftHistoryCountQueryVariables,
  MessageDraftHistoryQuery,
  MessageDraftHistoryQueryVariables
} from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import MessageCompareRevisions from '../MessageCompareRevisions/MessageCompareRevisions';
import MessageVersionDrawer from '../MessageVersionDrawer/MessageVersionDrawer';
import localStyles from './MessageVersionHistory.module.pcss';
import messageDraftHistoryQuery from './MessageVersionHistory.query.graphql';
import messageDraftHistoryCountQuery from './MessageVersionHistoryCount.query.graphql';

interface props {
  /**
   * The message of which the version history is to be shown.
   */
  message: MessageActionMenuFragment;
  /**
   *  Whether the component is a drop down item or not.
   */
  isDropDownItem?: boolean;
  /**
   * The class name to apply to the version button.
   */
  className?: string;
}

/**
 * Component to show the history of versions of a message
 *
 * @author Arjun Krishna
 */
const MessageVersionHistory: React.FC<React.PropsWithChildren<props>> = ({
  message,
  isDropDownItem = true,
  className
}) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage } = useTranslation(EndUserComponent.MESSAGE_VERSION_HISTORY);
  const uid = useId();
  const [showVersionWindow, setShowVersionModal] = useState(false);
  const [showCompareModal, setShowCompareModal] = useState(false);
  const [clickedVersion, setClickedVersion] = useState<MessageDraftHistoryEdge>(null);

  const { isAnonymous } = useRegistrationStatus();
  const displayVersions =
    message.board.conversationStyle === ConversationStyle.Tkb ||
    message.board.conversationStyle === ConversationStyle.Blog;

  const { data: policiesData, loading: policiesLoading } = useNodePolicies(
    module,
    {
      id: message.board.id,
      useCanViewMessageHistory: true
    },
    isAnonymous || !message
  );

  const { data: historyCount } = useQueryWithTracing<
    MessageDraftHistoryCountQuery,
    MessageDraftHistoryCountQueryVariables
  >(module, messageDraftHistoryCountQuery, {
    variables: {
      id: message?.id
    }
  });

  const { data: historyData, loading: queryLoading } = useQueryWithTracing<
    MessageDraftHistoryQuery,
    MessageDraftHistoryQueryVariables
  >(module, messageDraftHistoryQuery, {
    variables: {
      id: message?.id,
      first: (historyCount?.message as Article)?.contentWorkflow?.messageDraftHistory?.totalCount
    }
  });

  if (queryLoading || policiesLoading || !displayVersions) {
    return null;
  }

  const canViewMessageHistory =
    (policiesData?.coreNode as Board)?.boardPolicies?.canViewMessageHistory.failureReason === null;

  const userContext = (message as Article).contentWorkflow?.userContext;

  const canCoAuthorViewMessageHistory =
    userContext.canEdit || userContext.canSubmitForReview || userContext.canRecall;

  const versionList =
    ((historyData?.message as Article)?.contentWorkflow?.messageDraftHistory
      ?.edges as Array<MessageDraftHistoryEdge>) ?? [];

  const currentVersion = versionList && versionList[0]?.node.version;
  const publishedVersions = versionList.filter(version => {
    return version.node.workflowAction === WorkflowAction.Publish.toLowerCase();
  });
  const lastPublishedVersion = publishedVersions.length > 0 && publishedVersions[0]?.node.version;
  const latestMajorVersion = lastPublishedVersion ? lastPublishedVersion.major : '0';

  /**
   *  This list would include the versions of a message which is not published at all.
   */
  const postPublishedVersions = [
    ...new Map(
      versionList
        .filter(version => {
          return (
            version.node.workflowAction !== WorkflowAction.Publish.toLowerCase() &&
            version.node.version.major === latestMajorVersion &&
            !version.node.deleted &&
            !(
              isEqual(version.node.version, lastPublishedVersion) &&
              (version.node.workflowAction === CoAuthorsAndContributorsWorkflow.ADD_CONTRIBUTOR ||
                version.node.workflowAction ===
                  CoAuthorsAndContributorsWorkflow.REMOVE_CONTRIBUTOR ||
                version.node.workflowAction === CoAuthorsAndContributorsWorkflow.ADD_CO_AUTHOR ||
                version.node.workflowAction === CoAuthorsAndContributorsWorkflow.REMOVE_CO_AUTHOR)
            )
          );
        })
        .map(item => [item.node.revision, item])
    ).values()
  ];

  /**
   * To handle the comparison of 2 versions of a message
   *
   * @param highlightedVersion the version to be compared with.
   */
  function handleVersionCompare(highlightedVersion) {
    setShowCompareModal(true);
    setShowVersionModal(false);
    setClickedVersion(highlightedVersion);
  }

  /**
   * Renders the accordion with the versions of the message
   *
   * @param versions The list of versions.
   * @param publishState The state of publish (pre or post).
   *
   */
  function fetchTopLevelVersions(
    versions: Array<MessageDraftHistoryEdge>,
    publishState: PublishedState
  ): Array<React.ReactElement> {
    return versions.map(version => (
      <MessageVersionDrawer
        version={version}
        message={message}
        versionList={versionList}
        publishState={publishState}
        key={version.node.revision}
        onVersionCompare={handleVersionCompare}
      />
    ));
  }

  /**
   * Renders a modal window to show the article history
   */
  function renderArticleModal(): React.ReactElement {
    return (
      <Modal
        show={showVersionWindow}
        onHide={() => {
          setShowVersionModal(false);
        }}
        centered
        size="sm"
        aria-labelledby={`Modal-${uid}`}
        scrollable
        className={'lia-g-locked-modal'}
        dialogClassName={'lia-g-locked-modal-dialog'}
        contentClassName={'lia-g-locked-modal-content'}
      >
        <Modal.Header closeButton data-testid="MessageVersionHistory.Header">
          <Modal.Title as="h4" data-testid="MessageVersionHistory.Title">
            {formatMessage('versionHistory')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          data-testid="MessageVersionHistory.Body"
          className={'lia-g-locked-modal-body lia-g-mb-25'}
        >
          {postPublishedVersions.length > 0 && (
            <div className={cx('lia-g-mb-40')}>
              {fetchTopLevelVersions(postPublishedVersions, PublishedState.POST_PUBLISH)}
            </div>
          )}
          {publishedVersions.length > 0 && (
            <div>
              {postPublishedVersions.length > 0 && (
                <h5 className={cx('lia-g-mb-15')}>{formatMessage('publishedVersions')}</h5>
              )}
              {fetchTopLevelVersions(publishedVersions, PublishedState.PUBLISH)}
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }

  /**
   * Renders a modal window to compare two versions of a message.
   */
  function renderCompareVersionModal(): React.ReactElement {
    return (
      <MessageCompareRevisions
        show={showCompareModal}
        onHide={(): void => setShowCompareModal(false)}
        versionList={versionList}
        selectedVersion={clickedVersion}
      />
    );
  }

  /**
   * Renders the version button/ dropdown based on the prop.
   */
  function renderVersion() {
    return (
      <>
        {isDropDownItem ? (
          <Dropdown.Item
            onClick={(): void => {
              setShowVersionModal(true);
            }}
            data-testid="MessageVersionHistory.DropDownItem"
          >
            {formatMessage('history')}
          </Dropdown.Item>
        ) : (
          <Button
            onClick={(): void => {
              setShowVersionModal(true);
            }}
            type="button"
            className={cx(className)}
            variant={ButtonVariant.SECONDARY}
            data-testid="MessageVersionHistory.versionHistory"
          >
            <span className={cx('lia-version-number')}>
              {formatMessage('version', {
                major: currentVersion?.major,
                minor: currentVersion?.minor
              })}
            </span>
            {formatMessage('versionHistory')}
          </Button>
        )}
      </>
    );
  }

  return canViewMessageHistory || canCoAuthorViewMessageHistory ? (
    <>
      {renderVersion()}
      {renderArticleModal()}
      {showCompareModal && renderCompareVersionModal()}
    </>
  ) : null;
};

export default MessageVersionHistory;
